exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gigs-tsx": () => import("./../../../src/pages/gigs.tsx" /* webpackChunkName: "component---src-pages-gigs-tsx" */),
  "component---src-pages-home-alt-tsx": () => import("./../../../src/pages/home-alt.tsx" /* webpackChunkName: "component---src-pages-home-alt-tsx" */),
  "component---src-pages-impressum-alt-tsx": () => import("./../../../src/pages/impressum-alt.tsx" /* webpackChunkName: "component---src-pages-impressum-alt-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-remix-download-tsx": () => import("./../../../src/pages/remix-download.tsx" /* webpackChunkName: "component---src-pages-remix-download-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

